//retrieve types
export const ALL_CASHIER = 'ALL_CASHIER';
export const TABLE_RECORD = 'TABLE_RECORD';
export const MERCHANT_DETAIL = 'MERCHANT_DETAIL';
export const MERCHANT_TRANSACTION = 'MERCHANT_TRANSACTION';
export const ALL_USERNAMES= 'ALL_USERNAMES';
// export const ALL_IPG_TRANSACTIONS = 'ALL_IPG_TRANSACTIONS';
export const ALL_FAIL_IPG_TRANSACTIONS = 'ALL_FAIL_IPG_TRANSACTIONS';
// export const ALL_REFUND_TRANSACTION = 'ALL_REFUND_TRANSACTION';
export const ALL_REFERENCES = 'ALL_REFERENCES';
export const ALL_MERCHANT_CASHIER = 'ALL_MERCHANT_CASHIER';
export const TABLE_LOADER = 'TABLE_LOADER';

// merchant report
export const PAYMENT_TYPES_COUNTS = 'PAYMENT_TYPES_COUNTS';
export const PAYMENT_COUNTS = 'PAYMENT_COUNTS';

export const CHECK_STATE='CHECK_STATE';
