import * as actionTypes from './../Action/actionTypes';
import * as actionType from "../Action/actionTypes";

const initials ={
  paymentTypes:[],
  paymentCount:[]
};

const merchantReportReducer=(state=initials,action)=>{

  switch (action.type) {

    case actionType.PAYMENT_TYPES_COUNTS:
      return{
        ...state,
        paymentTypes:action.value
      };

    case actionType.PAYMENT_COUNTS:
      return{
        ...state,
        paymentCount:action.value
      };

    default:
      return state;
  }
};

export default merchantReportReducer;
