import axios from 'axios';
import Cookies from "js-cookie";
import cookie from "react-cookies";
import * as constValues from  './constant';

const instance = axios.create({

  //2
  // baseURL: 'http://54.255.82.8/admin'
  // baseURL: 'http://localhost:8082'
  baseURL:constValues.baseUrl_admin
});

instance.defaults.headers.post['Content-Type'] = 'application/json';

instance.interceptors.request.use(
  request => {
    if (Cookies.get('mer_access_token') !== undefined) {
      request.headers.Authorization = 'Bearer ' + Cookies.get('mer_access_token');
    }
    return request;
  },
  error => error
);

instance.interceptors.response.use(
  response => response,
  async (error) => {
    console.log(error.response.status);
    const status = error.response ? error.response.status : 0;
    if (status === 401) {
      const formdata = new FormData();
      formdata.set("refresh_token",Cookies.get('mer_refresh_token'));
      formdata.set("grant_type","refresh_token");


      let isAccessTokenRefreshed = false;
      await axios.post(constValues.baseUrl_admin+'/oauth/token', formdata,{auth:{username:'ceyentra',password:''}} )
        .then(response => {
          if (response !== undefined){
            cookie.save('mer_access_token',response.data.access_token, { path: '/' });
            cookie.save('mer_refresh_token',response.data.refresh_token, { path: '/' });
            axios.defaults.headers.common = {'Authorization': `bearer `+Cookies.get('mer_access_token')};
            isAccessTokenRefreshed = true;
          }
        })
        .catch(err => {
          console.log(err)
          // Cookies.remove('access_token');
          // Cookies.remove('refresh_token');
          // Cookies.remove('adminId');
          window.location = "/";
        });
      if (isAccessTokenRefreshed) {
        error.config.headers['Authorization'] = 'Bearer ' + Cookies.get('mer_access_token');
        return axios.request(error.config);
      }
    }
  }
);

export default instance;
