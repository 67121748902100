import * as actionType from './../Action/actionTypes';


const initials ={
  cashier:[],
  total_record:0,
  merchant:[],
  transaction:[],
  userName:[],
  // ipgTransaction:[],
  failIpgTransaction:[],
  // refundTransaction:[],
  references:[],
  merchantCashier:[],
  tLoader:false,
  checkState : false,
};

const retrieveReducer=(state=initials, action)=>{

  switch (action.type) {

    case actionType.ALL_CASHIER:
      return{
        ...state,
        cashier:action.value
      };

    case actionType.TABLE_RECORD:
      return{
        ...state,
        total_record:action.value
      };

    case actionType.MERCHANT_DETAIL:
      return{
        ...state,
        merchant:action.value
      };

    case actionType.MERCHANT_TRANSACTION:
      return{
        ...state,
        transaction:action.value
      };

    case actionType.ALL_USERNAMES:
      return{
        ...state,
        userName:action.value
      };

    // case actionType.ALL_IPG_TRANSACTIONS:
    //   return{
    //     ...state,
    //     ipgTransaction:action.value
    //   };

    case actionType.ALL_FAIL_IPG_TRANSACTIONS:
      return{
        ...state,
        failIpgTransaction:action.value
      };

    // case actionType.ALL_REFUND_TRANSACTION:
    //   return{
    //     ...state,
    //     refundTransaction:action.value
    //   };

    case actionType.ALL_REFERENCES:
      return{
        ...state,
        references:action.value
      };

    case actionType.ALL_MERCHANT_CASHIER:
      return{
        ...state,
        merchantCashier:action.value
      };

    case actionType.CHECK_STATE:
      return {
        ...state,
        checkState: action.value
      }

    case actionType.TABLE_LOADER:
      return{
        ...state,
        tLoader:action.value
      };

    default:
      return state;
  }


};

export default retrieveReducer;
